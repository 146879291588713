import React, { useState, useEffect, FC, useMemo, useRef } from 'react';
import { Link } from 'gatsby';
import { Launch, getUpcomingLaunches, getPastLaunches, Tag } from 'superclient';
import UpcomingLaunchCard from '../components/launches/launch-card-upcoming';
import LaunchCard from '../components/launches/launch-card';
import LaunchArchiveCard from '../components/launches/launch-archive-card';
import MetaModule from '../components/core/meta-module';
import { DateTime, Interval } from 'luxon';
import { useIntersect } from '../state/use-intersect';
import classNames from 'classnames';
import { Superclient } from '../clients/superclient';
import LaunchFilterBanner from '../components/launches/launch-filter-banner';
import { useQueryBool } from '../state/use-query';

const PAGE_LENGTH = 9;

const CustomStation: FC<{
  pageContext: {
    upcomingLaunches: Launch[];
    pastLaunches: Launch[];
    tags: Tag[];
  };
}> = ({
  pageContext: {
    upcomingLaunches: _upcomingLaunches,
    pastLaunches: _pastLaunches,
    tags
  }
}) => {
  const [upcomingLaunches, setUpcomingLaunches] = useState<Launch[]>(
    _upcomingLaunches
  );
  const [pastLaunches, setPastLaunches] = useState<Launch[]>(_pastLaunches);
  const content = React.createRef<HTMLDivElement>();

  const [selectedTag, setSelectedTag] = useState<Tag>();
  const [page, setPage] = useState(1);

  const loadMoreRef = useRef<any>(null);
  const [setNode, entry] = useIntersect();
  const [seeUpcoming, setSeeUpcoming] = useQueryBool('upcoming', true);

  useEffect(() => {
    Superclient.getUpcomingLaunches().then((upcomingLaunches) => {
      const publishedLaunches = upcomingLaunches.filter((l) => {
        const age = Interval.fromDateTimes(
          DateTime.fromISO(l._createdAt),
          DateTime.local()
        )
          .toDuration('minutes')
          .toObject().minutes;
        return age >= 12;
      });
      setUpcomingLaunches(publishedLaunches);
      Superclient.getPastLaunches().then(setPastLaunches);
    });
  }, []);

  const nextLaunch = useMemo(
    () => (upcomingLaunches.length > 0 ? upcomingLaunches[0] : undefined),
    [upcomingLaunches]
  );

  const pastLaunchesFiltered = useMemo(() => {
    return selectedTag
      ? pastLaunches.filter((l) => {
          const tags = l.tags ? l.tags.map((t) => t._id) : [];
          return tags.includes(selectedTag._id);
        })
      : pastLaunches;
  }, [pastLaunches, selectedTag]);

  const pastLaunchResults = useMemo(() => {
    return pastLaunchesFiltered.slice(0, page * PAGE_LENGTH);
  }, [page, pastLaunchesFiltered]);

  useEffect(() => {
    setNode(loadMoreRef.current);
  }, [setNode]);

  useEffect(() => {
    if (
      entry?.isIntersecting &&
      pastLaunchesFiltered.length > pastLaunchResults.length
    ) {
      setPage(page + 1);
    }
  }, [entry, page, pastLaunchResults, pastLaunchesFiltered]);

  const loadMore = useMemo(() => {
    if (pastLaunchesFiltered.length > pastLaunchResults.length) {
      return <div className="caps xsmall cw py1 my1 pointer under">Loading...</div>;
    } else {
      return <span />;
    }
  }, [pastLaunchResults, pastLaunchesFiltered]);

  const selectedLaunches = seeUpcoming ? upcomingLaunches : pastLaunches;

  return (
    <div className="template__launches">
      <MetaModule title="Launches" path="/launches" />
      <LaunchFilterBanner
        seeUpcoming={seeUpcoming}
        onToggleSeeUpcoming={setSeeUpcoming}
      />

      <div ref={content}>
        {nextLaunch !== undefined && seeUpcoming && (
          <div
            className={classNames(
              `rel launch__next-big ${
                seeUpcoming ? 'lightMode__primary' : 'darkMode'
              }`
            )}
          >
            <Link
              to={`/launches/${nextLaunch.slug.current}`}
              className="abs z1 x y top left"
            />
            <div className="container--xl ma px15 pb5 pt1">
              <div style={{ paddingTop: '5rem' }}>
                <UpcomingLaunchCard
                  launch={nextLaunch}
                  seeUpcoming={seeUpcoming}
                  isNextLaunch={false}
                />
              </div>
            </div>
          </div>
        )}
        <div className={classNames(`${seeUpcoming ? 'lightMode' : 'darkMode'}`)}>
          <div className="al">
            <div className="container--xl ma p15 launch__index">
              <div className="article__filters launch__filters pb15 f jcc cb aic caps" />
              <div className="f jcb ais fw">
                {selectedLaunches.length ? (
                  selectedLaunches
                    .slice(seeUpcoming ? 1 : 0, 4)
                    .map((launch) => (
                      <UpcomingLaunchCard
                        key={launch._id}
                        launch={launch}
                        seeUpcoming={seeUpcoming}
                        isNextLaunch={true}
                      />
                    ))
                ) : (
                  <div className="article__filters-empty ac h3 p15 f cb jcc aic">
                    {`We're sorry, ${
                      seeUpcoming
                        ? "there's currently nothing launching"
                        : 'there are no launches in the archive right now.'
                    }`}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={classNames(`${seeUpcoming ? 'lightMode' : 'darkMode'}`)}>
          <div className="container--xl ma p15">
            <div>
              {selectedLaunches.length > 3 && (
                <div className="f jcb ais fw">
                  {selectedLaunches
                    .slice(4, selectedLaunches.length)
                    .map((launch) => (
                      <LaunchCard
                        key={launch._id}
                        launch={launch}
                        seeUpcoming={seeUpcoming}
                      />
                    ))}
                  <div className="launch__card rel" />
                </div>
              )}
            </div>
          </div>

          <div className="bcb cw rel launch__archive">
            <div
              className={classNames(
                `x jcc f aic cw py1 ${seeUpcoming ? 'lightMode' : 'darkMode'}`
              )}
              ref={loadMoreRef}
            >
              {loadMore}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomStation;
