import React, { FC } from 'react';
import classNames from 'classnames';

const LaunchFilterBanner: FC<{
  seeUpcoming: boolean;
  onToggleSeeUpcoming: (value: boolean) => void;
}> = ({ seeUpcoming, onToggleSeeUpcoming }) => {
  return (
    <div
      className={classNames(`fix z10 mb3 ${seeUpcoming ? 'bcg' : 'bcb'}`)}
      style={{
        width: '100%'
      }}
    >
      <div className="container--xl ma">
        <div
          className={classNames(
            `${seeUpcoming ? 'cb' : 'cw'} launch__next f jcs aic rel caps sans h5`
          )}
        >
          Upcoming
          <div
            className={classNames('astronaut_filters__switch pointer f mx1', {
              jce: !seeUpcoming,
              invertBlackWhite: !seeUpcoming
            })}
            onClick={() => {
              onToggleSeeUpcoming(!seeUpcoming);
            }}
          >
            <div className="astronaut_filters__switch_bubble" />
          </div>
          Past
        </div>
      </div>
    </div>
  );
};

export default LaunchFilterBanner;
