import React, { FC, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import MetaModule from '../components/core/meta-module';
import LogoLong from '../components/svgs/longLogo';
import { validateEmail } from 'superclient';
import { useQueryString } from '../state/use-query';

const TemplateSupport: FC = () => {
  const paymentOptions = [25, 50, 100, -1, 500, 1000, 5000, -1, 0];

  const [email, setEmail] = useState('');
  const [donationAmount, setDonationAmount] = useState<number>(25);
  const [customDonationAmount, setCustomDonationAmount] = useState(0);
  const [payForFees, setPayForFees] = useState(true);
  const [donateMonthly, setDonateMonthly] = useState(false);
  const [grandTotal, setGrandTotal] = useState<number | string>(0);
  const [grandTotalForURL, setGrandTotalForURL] = useState<number | string>(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const [donationSuccess, setDonationSuccess] = useQueryString('donation');
  const [error, setError] = useState(false);

  const singleDonationLink = 'https://donate.stripe.com/00gbKE0cQdDl0pyfYZ';
  const monthlyDonationLink = 'https://donate.stripe.com/4gwbKE7Ficzh0pydQS';
  const params = `?prefilled_email=${email}&__prefilled_amount=${grandTotalForURL}`;

  useEffect(() => {
    let baseTotal = donationAmount === 0 ? customDonationAmount : donationAmount;
    if (payForFees) baseTotal = baseTotal * 1.03;
    const convertGrandTotalForURL = Number(Math.round(baseTotal * 100));
    const convertToDollars = Number(Math.round(baseTotal * 100) / 100).toFixed(2);
    setGrandTotal(convertToDollars);
    setGrandTotalForURL(convertGrandTotalForURL);
  }, [donationAmount, customDonationAmount, payForFees]);

  useEffect(() => {});

  const goToStripeCheckout = (e) => {
    e.preventDefault();
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) setError(true);
    if (!initialLoad && isValidEmail) {
      const link = donateMonthly
        ? monthlyDonationLink + params
        : singleDonationLink + params;

      window.location.href = link;
    }
  };

  return (
    <div className="x my2 f aic jcc">
      <MetaModule title="Support" path="/support" />
      <div className="support container--s fc aic jcc bau px1 fixed top sm:px3">
        <Link to={'/'} className="f jcc mb2">
          <LogoLong className="header__site__logo p0" />
        </Link>
        {donationSuccess ? (
          <h2 className="support__header ac my15">Thank you for your donation.</h2>
        ) : (
          <h2 className="support__header ac my15">
            Support <br /> Supercluster <br /> with a Donation
          </h2>
        )}
        <p className="support__description ac mb3">
          Love Supercluster? We'd love the support. <br />
          <br />
          By becoming a contributor, your donation helps us maintain our digital
          infrastructure, work with the best writers, artists, and photographers in
          the industry, and keep our apps free for space fans everywhere.
        </p>
        {donationSuccess ? (
          <div>
            <Link to={`/`}>
              <button
                type="button"
                className="support__blocks__bottom bcb border cw x my2 caps b small p1"
              >
                Back to supercluster
              </button>
            </Link>
          </div>
        ) : (
          <form>
            <div className="f caps pt2 aic gap support__toggle">
              <div
                className={`support__switch${
                  donateMonthly ? '__active' : ''
                } pointer f`}
                onClick={() => setDonateMonthly(!donateMonthly)}
              >
                <div className="support__switch__bubble pointer f" />
              </div>
              Donate Monthly
            </div>

            <input
              type="email"
              className="border caps x mt15 support__field"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {error && <div className="small mt1 cr">Invalid Email</div>}
            <div className="support__paymentAmounts">
              {paymentOptions.map((option, i) => {
                return option === -1 ? (
                  <div key={i} className="x mt1"></div>
                ) : (
                  <div key={i} className="x mt1">
                    <label className="f">
                      <input
                        type="radio"
                        name="donationAmount"
                        value={paymentOptions[i]}
                        onChange={(e) => {
                          setInitialLoad(false);
                          setCustomDonationAmount(0);
                          setDonationAmount(Number(e.target.value));
                        }}
                        required
                      />
                      {`${option === 0 ? 'OTHER' : `$${option}`}`}
                    </label>
                  </div>
                );
              })}
            </div>

            {donationAmount === 0 && (
              <input
                type="number"
                className="border caps x mt15 support__field"
                placeholder="Gift amt (USD)"
                min="0"
                step=".01"
                pattern="^\d+(?:\.\d{1,2})?$"
                value={customDonationAmount === 0 ? '' : customDonationAmount}
                onChange={(e) => {
                  const donationString = Number(e.target.value).toFixed(2);
                  setCustomDonationAmount(Number(donationString));
                }}
              />
            )}

            <div className="small mt2">
              Our payment processor (Stripe) charges a 3% fee per transaction. Select
              the option below to ensure that Supercluster receives 100% of your
              intended gift amount.
            </div>

            <div className="f caps py2 aic gap support__toggle">
              <div
                className={`support__switch${
                  payForFees ? '__active' : ''
                } pointer f`}
                onClick={() => setPayForFees(!payForFees)}
              >
                <div className="support__switch__bubble pointer f" />
              </div>
              Pay for fees
            </div>

            <div className="bcg caps p1 mt1 b support__blocks small">
              <div className="f jcb mx015">
                Total Gift
                <span>{`$ ${initialLoad ? '0.00' : grandTotal} ${
                  donateMonthly ? '/ month' : ''
                }`}</span>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="support__blocks__bottom bcb border cw x my2 caps b small p1"
                onClick={goToStripeCheckout}
              >
                {`Donate ${donateMonthly ? 'monthly' : ''} to Supercluster`}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default TemplateSupport;
