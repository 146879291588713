import React, { FC, useRef, useState, useLayoutEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import BlockContent from '@sanity/block-content-to-react';
import { LaunchModule } from 'superclient';
import ImageBackgroundSanity from '../core/image-background-sanity';
import { useWindowSize } from '../../state/use-window-size';

const LaunchBlock: FC<{
  launchModule: LaunchModule;
  launchDate: Date;
}> = ({ launchModule, launchDate }) => {
  const { module: m, customTitle } = launchModule;
  if (!m) return null;
  const imageHeight = useRef(null);
  const payloadHeight = useRef(null);
  const blockContentHeight = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);
  const [textPayloadHeight, setTextPayloadHeight] = useState(0);
  const [blockContentContainerHeight, setBlockContentContainerHeight] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const toggleReadMore = () => {
    setExpanded(!expanded);
  };
  useLayoutEffect(() => {
    setMaxHeight(imageHeight.current.offsetHeight);
    setTextPayloadHeight(payloadHeight.current.offsetHeight);
    setBlockContentContainerHeight(blockContentHeight.current.offsetHeight);
  }, [blockContentHeight?.current?.offsetHeight]);

  const windowSize = useWindowSize();
  const isMobile = useMemo(() => windowSize.width <= 900, [windowSize]);

  return (
    <div className="launch__payload main">
      <div
        className={`launch__payload_text f fc aic no-scrollbar ${
          expanded ? 'expanded' : ''
        }`}
      >
        <div ref={payloadHeight} className="launch__payload_text_inner f fc p15">
          <div className="f launch__payload_header">
            <div className="x mb1">
              {customTitle.map((cTitle, i) => (
                <h1 key={i} className="h0 x cb m0 p0">
                  {cTitle}
                  {customTitle.length - 1 === i && (
                    <div className="arrow__left rel" />
                  )}
                  {customTitle.length - 1 === i && (
                    <div className="arrow__right rel" />
                  )}
                </h1>
              ))}
            </div>
          </div>
          <div className="s1">
            <h4 className="caps mt15 pt1">
              {m?.title}
              <br />
            </h4>
            {m && m.description && (
              <div
                className={`${isMobile ? 'expandToggleMobile' : ''} 
                ${expanded ? 'readMoreMobile' : 'readLessMobile'}`}
              >
                <div ref={blockContentHeight}>
                  <BlockContent blocks={m.description} />
                </div>
              </div>
            )}
            {/* Account for padding, fade, and mobile height on block content */}
            {(isMobile
              ? blockContentContainerHeight < 200
                ? blockContentContainerHeight
                : blockContentContainerHeight + 300
              : textPayloadHeight + 50) > maxHeight ? (
              <button
                className={`expandToggle ${expanded ? 'readLess' : 'readMore'}`}
                onClick={toggleReadMore}
              >
                {expanded ? 'READ LESS' : 'READ MORE'}
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="launch__payload_image rel" ref={imageHeight}>
        <ImageBackgroundSanity
          image={m?.image}
          alt={m?.title}
          width={700}
          lazyLoad={true}
          fadeIn
          className="abs x y top left right fill"
        />
      </div>
    </div>
  );
};

export default LaunchBlock;
