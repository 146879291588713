import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import { DateTime, Interval } from 'luxon';
import { Craft, SanityDate, zeroPad } from 'superclient';
import ImageBackgroundSanity from '../../core/image-background-sanity';
import CloseButton from '../../svgs/close-button';
import * as styles from './station-craft-preview.module.scss';

const isDST = (d) => {
  const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== d.getTimezoneOffset();
};

const StationCraftPreview: FC<{
  isMobile: boolean;
  portName: string;
  portNumber: string;
  craft?: Craft;
  craftUniqueName?: string;
  craftArrivalDate?: SanityDate;
  craftOverrideArrivalDate?: string;
  craftDepartureDate?: SanityDate;
  craftOverrideDepartureDate?: string;
  onClose: () => void;
}> = ({
  isMobile,
  portName,
  portNumber,
  craft,
  craftUniqueName,
  craftArrivalDate,
  craftOverrideArrivalDate,
  craftDepartureDate,
  craftOverrideDepartureDate,
  onClose
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let mounted = true;

    setTimeout(() => {
      if (mounted) {
        setCount(count + 1);
      }
    }, 1000);

    return () => {
      mounted = false;
    };
  }, [count]);

  const t = Interval.fromDateTimes(
    DateTime.fromISO(craftArrivalDate?.utc || ''),
    DateTime.now()
  )
    .toDuration('seconds')
    .toObject().seconds;

  const seconds = Math.floor(t % 60);
  const minutes = Math.floor((t / 60) % 60);
  const hours = Math.floor((t / (60 * 60)) % 24);
  const days = Math.floor(t / (60 * 60 * 24));
  const timeAtStation = `${zeroPad(days, 2)}:${zeroPad(hours, 2)}:${zeroPad(
    minutes,
    2
  )}:${zeroPad(seconds, 2)}`;

  return (
    <div className="x y f fc">
      {isMobile && (
        <div className={`bcbl f fr ${styles.station_craft_preview__bar} aic`}>
          <div className="akkura xsmall caps cb ml15">
            {`${portNumber} - ${portName}`}
          </div>
          <div className="f1" />
          <button onClick={onClose} className="f fr aic jcc ml1">
            <CloseButton />
          </button>
        </div>
      )}
      <div
        className={classnames(`f fr ${styles.station_craft_preview__contents} x y`)}
      >
        <div
          className={classnames(
            `f1 bcb ovfh rel ${styles.station_craft_preview__contents}`
          )}
        >
          <ImageBackgroundSanity
            image={craft?.heroImage}
            alt={craft?.title}
            width={300}
            height={300}
            lazyLoad
          />
        </div>
        <div className="bcb y width-1" />
        <div className={`f1 bcw cb ${styles.station_craft_preview__body}`}>
          <div className="pl1 py075 f fc pr1 y">
            <div className="f aifs jcb">
              <div className="x pb1 bau h5 caps pr2 bcw">{craftUniqueName}</div>
              {!isMobile && (
                <button className="z10 p025 bcw" onClick={onClose}>
                  <CloseButton />
                </button>
              )}
            </div>
            <div className="f1 x ovfys pr1 light-scrollbar">
              <div className="akkura xsmall caps">Arrival</div>
              <div className="akkura xsmall caps mb05">
                {craftOverrideArrivalDate && craftOverrideArrivalDate.length > 0 ? (
                  craftOverrideArrivalDate
                ) : (
                  <>
                    {DateTime.fromISO(craftArrivalDate?.utc || '').toFormat('DD t ')}
                    <span className={`xxsmall ${styles.station_craft_preview__tz}`}>
                      {DateTime.fromISO(craftArrivalDate?.utc || '').toFormat(
                        `'(UTC 'Z')'`
                      )}
                    </span>
                  </>
                )}
              </div>
              <div className="akkura xsmall caps">Departure</div>
              <div className="akkura xsmall caps mb05">
                {craftOverrideDepartureDate &&
                craftOverrideDepartureDate.length > 0 ? (
                  craftOverrideDepartureDate
                ) : craftDepartureDate && craftDepartureDate.utc ? (
                  <>
                    {DateTime.fromISO(craftDepartureDate?.utc || '').toFormat(
                      'DD t '
                    )}
                    <span className={`xxsmall ${styles.station_craft_preview__tz}`}>
                      {DateTime.fromISO(craftDepartureDate?.utc || '').toFormat(
                        'ZZZZ'
                      )}
                    </span>
                  </>
                ) : (
                  'TBD'
                )}
              </div>
              <div className="akkura xsmall caps">Time at Station</div>
              <div className="akkura xsmall caps pb1">{timeAtStation}</div>
              <div
                className="bau small"
                style={{ paddingBottom: isMobile ? '3rem' : '0' }}
              >
                {craft?.shortDescription}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StationCraftPreview;
